import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './PostDetail.css';  // Pastikan untuk mengimpor file CSS yang telah dibuat

const PostDetail = () => {
  const { url } = useParams(); // Mengambil URL dari parameter
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null); // Menyimpan error jika terjadi kesalahan
  const [isLoading, setIsLoading] = useState(true); // Menambahkan status loading

  useEffect(() => {
    setIsLoading(true); // Set loading ke true setiap kali URL berubah
    // Panggil API untuk mendapatkan artikel berdasarkan URL
    axios
      .get(`https://web.infoupiyptk.com/api/post/get-post.php?url=${url}`)
      .then(response => {
        if (response.status === 200) {
          setPost(response.data);
        } else {
          setError('Post not found');
        }
      })
      .catch(error => {
        setError('There was an error fetching the post!');
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading ke false setelah fetch selesai
      });
  }, [url]);

  if (isLoading) {
    return <div>Loading...</div>; // Tampilkan loading jika data belum diterima
  }

  if (error) {
    return <div>Error: {error}</div>; // Tampilkan error jika ada
  }

  // Format tanggal menjadi 26 November 2024
  const formattedDate = new Date(post.created_at).toLocaleDateString('id-ID', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div className="post-detail">
      {/* Breadcrumb */}
      <div className="breadcrumb">
        <a href="/">Home </a> 
        <span style={{ fontSize: '13px', margin: '0px 5px', color: '#FFD62C' }}> / </span> 
        <span style={{ fontSize: '13px' }}> {post.categories}</span>
      </div>

      {/* Title */}
      <h1 className="post-title-detail">{post.title}</h1>

      {/* Meta data (Date and Category) */}
      <div className="post-meta">
        {formattedDate} - {post.categories}
      </div>

      {/* Featured Image */}
      <div className="post-featured-image-container">
        <img
          src={`https://web.infoupiyptk.com/api/post/${post.featured_image}`}
          alt={post.title}  // Dynamic alt text based on title
          className="post-featured-image"
        />
      </div>

      {/* Post Body */}
      <div
        className="post-body"
        dangerouslySetInnerHTML={{ __html: post.body }} // Untuk body dengan HTML
      />

      {/* Tags */}
      <div className="post-tags">
        <span style={{ marginRight: '10px', fontWeight: 'bold' }}>Tags:</span>
        {post.tags.split(' ').map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
    </div>
  );
};

export default PostDetail;
