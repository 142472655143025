import React from 'react';
import './ProgramStudi.css'; // Tambahkan file CSS untuk styling

const ProgramStudi = () => {
  return (
    <div className="program-studi-container">
         {/* Header Section */}
         <header className="header-section">
            <div className="header-content">
            <h2>Program Studi</h2>
            <hr className="header-line" />
            </div>
        </header>

        <div className='content-prodi'>
        <p>
            Program-program gelar sarjana dan pascasarjana yang ditawarkan di UPI YPTK Padang mencakup spektrum dan mata kuliah yang luas dan komprehensif, di berbagai bidang dan fokus penelitian. Spektrum disiplin ilmu meliputi ilmu komputer, ekonomi dan bisnis, psikologi, teknik, desain komunikasi visual hingga keguruan dan ilmu pendidikan.
        </p>
        <p>
            Kampus UPI YPTK Padang menciptakan lingkungan optimal untuk studi individu dan interdisipliner. Mahasiswa dapat memperoleh berbagai gelar dalam program sarjana dan pascasarjana.
        </p>

        <div className="program-section">
            <h3>Gelar Sarjana</h3>
            <a href="https://filkom.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Ilmu Komputer</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fekon.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Ekonomi dan Bisnis</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fdkv.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Desain Komunikasi Visual</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fpsi.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Psikologi</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fkip.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Keguruan dan Ilmu Pendidikan</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://teknik.upiyptk.ac.id/" className="program-link">
            <span>Sarjana Teknik</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
        </div>

        <div className="program-section">
            <h3>Gelar Master</h3>
            <a href="https://filkom.upiyptk.ac.id/magister-teknik-informatika-s2/" className="program-link">
            <span>Master Ilmu Komputer</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fekon.upiyptk.ac.id/graduate-program-s2/" className="program-link">
            <span>Master Manajemen</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
        </div>

        <div className="program-section">
            <h3>Gelar Doktor</h3>
            <a href="https://filkom.upiyptk.ac.id/phd-teknologi-informasi/" className="program-link">
            <span>Doktor Teknologi Informasi</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
            <a href="https://fekon.upiyptk.ac.id/postgraduate-program-s3/" className="program-link">
            <span>Doktor Manajemen</span>
            <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
            </a>
        </div>
        </div>
    </div>
  );
};

export default ProgramStudi;
