import React from 'react';
import { Container, Row } from 'reactstrap';
import './Newsroom.css';
import AllPost from '../post/AllPost';

const posts = [
  {
    title: 'UPI YPTK Padang Mengadakan Webinar Teknologi Terbaru',
    date: '12 Desember 2024',
    description: 'Webinar ini membahas teknologi terkini di bidang pendidikan dan teknologi informasi...',
    link: '#',
  },
  {
    title: 'Penerimaan Mahasiswa Baru 2024',
    date: '5 Desember 2024',
    description: 'UPI YPTK Padang membuka pendaftaran untuk mahasiswa baru dengan berbagai program studi...',
    link: '#',
  },
  {
    title: 'UPI YPTK Padang Menjadi Tuan Rumah Seminar Nasional',
    date: '30 November 2024',
    description: 'Seminar nasional yang membahas inovasi dalam dunia pendidikan diadakan di kampus UPI YPTK...',
    link: '#',
  },
];

const Newsroom = () => {
  return (
    <div className="newsroom-container">
        {/* Header Section */}
        <header className="header-section">
          <div className="header-content">
            <h2>NEWSROOM</h2>
            <hr className="header-line" />
          </div>
        </header>

        <Container className="mt-5">
          <Row>
            {/* Menggunakan AllPost untuk menampilkan daftar berita */}
            <AllPost posts={posts} />
          </Row>
        </Container>
    </div>
  );
};

export default Newsroom;
