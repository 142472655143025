import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Post from "./post/submitPost";
import PostDetail from "./post/postDetail";
import AllPost from "./post/AllPost";
import Highlight from "./post/Highlight";

import 'font-awesome/css/font-awesome.min.css';
import NavbarHeader from "./components/NavbarHeader";
import MainFooter from "./components/MainFooter";

import HomePage from "./pages/HomePage";
import SejarahUpiYptk from "./pages/SejarahUpiYptk";
import VisiMisi from "./pages/VisiMisi";
import PimpinanKampus from "./pages/PimpinanKampus";
import StrukturOrganisasi from "./pages/StrukturOrganisasi";
import MarsUpiYptk from "./pages/MarsUpiYptk";
import FasilitasUpiYptk from "./pages/FasilitasUpiYptk";
import AkreditasiKampus from "./pages/AkreditasiKampus";
import ProgramKreatifitasMahasiswa from "./pages/ProgramKreatifitasMahasiswa";
import Beasiswa from "./pages/Beasiswa";
import Kontak from "./pages/Kontak";
import Newsroom from "./pages/Newsroom";
import ProgramStudi from "./pages/ProgramStudi";

import Page404 from "./pages/404Page";


function App() {
  return (
    <Router>
      <NavbarHeader/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/post" element={<Post />} />
        {/* Perbaiki ini dengan menggunakan 'element' */}
        <Route path="/post/:url" element={<PostDetail />} />
        <Route path="/all" element={<AllPost />} />
        <Route path="/highlight" element={<Highlight />} />
        <Route path="/sejarah-upi-yptk" element={<SejarahUpiYptk />} />
        <Route path="/visi-misi" element={<VisiMisi/>} />
        <Route path="/pimpinan-kampus" element={<PimpinanKampus />} />
        <Route path="/struktur-organisasi-kampus" element={<StrukturOrganisasi/>} />
        <Route path="/mars-upi-yptk" element={<MarsUpiYptk />} />
        <Route path="/fasilitas-upi-yptk" element={<FasilitasUpiYptk/>} />
        <Route path="/akreditasi-kampus" element={<AkreditasiKampus />} />
        <Route path="/pkm-upi-yptk" element={<ProgramKreatifitasMahasiswa />} />
        <Route path="/beasiswa" element={<Beasiswa />} />
        <Route path="/kontak" element={<Kontak />} />
        <Route path="/newsroom" element={<Newsroom/>} />
        <Route path="/program-studi" element={<ProgramStudi/>} />

        {/* Menangani rute yang tidak ditemukan (halaman 404) */}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <MainFooter/>
    </Router>
  );
}

export default App;
