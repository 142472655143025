import React, { useRef, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import * as THREE from 'three';

// Komponen efek bunga elektrik
const Page404 = () => {
  const meshRef = useRef();
  const cameraRef = useRef();
  const [time, setTime] = useState(0);

  // Efek untuk pergerakan bunga elektrik dan latar belakang
  useEffect(() => {
    if (!meshRef.current) return;

    // Membuat material untuk efek bunga elektrik
    const material = new THREE.ShaderMaterial({
      vertexShader: `
        attribute vec3 position;
        attribute vec2 texCoord;
        uniform mat4 u_worldviewproj;
        uniform float u_time;
        varying vec2 v_texCoord;

        void main(void) {
          v_texCoord = texCoord;
          vec3 pos = position;

          // Animasi pergerakan bunga secara dinamis
          pos.x += sin(u_time + texCoord.x * 3.0) * 0.5;
          pos.y += cos(u_time + texCoord.y * 3.0) * 0.5;

          gl_Position = u_worldviewproj * vec4(pos, 1.0);
        }`,
      fragmentShader: `
        precision mediump float;
        varying vec2 v_texCoord;

        void main(void) {
          // Efek warna yang dinamis berdasarkan waktu
          gl_FragColor = vec4(0.5 + 0.5 * sin(v_texCoord.x * 10.0 + u_time), 
                              0.5 + 0.5 * cos(v_texCoord.y * 10.0 + u_time),
                              1.0, 
                              1.0);
        }`,
      uniforms: {
        u_time: { value: time },
        u_worldviewproj: { value: new THREE.Matrix4() },
      },
    });

    const geometry = new THREE.PlaneGeometry(5, 5, 32, 32);
    const flower = new THREE.Mesh(geometry, material);
    meshRef.current.add(flower);

    // Animasi untuk memperbarui waktu bunga
    const animateFlower = () => {
      material.uniforms.u_time.value += 0.01;
      requestAnimationFrame(animateFlower);
    };
    animateFlower();
  }, [time]);

  // Efek kamera bergerak otomatis untuk memberikan sensasi menjelajah ruang angkasa
  useEffect(() => {
    const animateCamera = () => {
      if (cameraRef.current) {
        // Pergerakan kamera maju
        cameraRef.current.position.x += Math.sin(time * 0.2) * 0.1;
        cameraRef.current.position.y += Math.cos(time * 0.2) * 0.1;
        cameraRef.current.position.z -= 0.2;

        // Kamera berputar untuk efek ruang angkasa
        cameraRef.current.rotation.x += 0.001;
        cameraRef.current.rotation.y += 0.001;
      }

      requestAnimationFrame(animateCamera);
    };
    animateCamera();
  }, [time]);

  return (
    <div style={{ height: '100vh', background: 'black' }}>
      <Canvas
        camera={{ position: [0, 0, 5], fov: 75 }}
        style={{ display: 'block', width: '100%', height: '100%' }}
      >
        <ambientLight intensity={0.5} />
        <Stars />
        <group ref={meshRef}></group>
        <perspectiveCamera ref={cameraRef} position={[0, 0, 5]} />
      </Canvas>
      <div style={styles.text}>
        <h5 style={styles.heading}>404 - You're too Far!</h5>
        <p style={styles.subheading}>Stay in the path champ</p>
      </div>
    </div>
  );
};

// Styling untuk teks di atas canvas
const styles = {
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    textAlign: 'center',
    zIndex: 2,
  },
  heading: {
    fontSize: '3rem',
    margin: 0,
    textAlign: 'left',
  },
  subheading: {
    fontSize: '1.5rem',
    textAlign:'left',
  },
};

export default Page404;
