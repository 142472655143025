import React from 'react';
import './MarsUpiYptk.css';

const MarsUpiYptk = () => {
  return (
    <div className="mars-upi-ytpk-container">
      {/* Header Section */}
      <header className="header-section">
        <div className="header-content">
          <h2>MARS UPI YPTK</h2>
          <hr className="header-line" />
        </div>
      </header>

      {/* Video YouTube */}
      <section className="video-section">
        <div className="video-container">
          <iframe
            width="100%"
            // height="500"
            src="https://www.youtube.com/embed/lyCbCMT_n9A"  // Ganti VIDEO_ID dengan ID video YouTube yang sesuai
            title="Mars UPI YPTK"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Lirik Lagu Mars UPI YPTK */}
      <section className="lyrics-section">
        <h3>Mars UPI YPTK</h3>
        <hr className='lyrics-line' />
        <div className="lyrics-container">   
            <div className="lyrics">
                {/* Lirik lagu Mars UPI YPTK Padang */}
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PEMBINA SUMBERDAYA MANUSIA</p>
                <p>DAN ILMUAN UNGGUL DI CAKRAWALA ILMU</p>
                <br />
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PENGEMBANG KARYA CIPTA ILMIAH</p>
                <p>RUANG OLAH ILMU DAN PENGETAHUAN</p>
                <br />
                <p>DENGAN SMANGAT MELANGKAH LEBIH MAJU</p>
                <p>DI ERA TEKNOLOGI INFORMASI</p>
                <p>TANAMKAN IMAN TAQWA DAN BUDAYA</p>
                <p>WUJUDKAN INSAN BERBUDI LUHUR</p>
                <br />
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PENGABDIAN MENGAMALKAN ILMU</p>
                <p>DEMI TANAH AIR NUSA BANGSA JAYA</p>
                <br />
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PEMBINA SUMBERDAYA MANUSIA</p>
                <p>DAN ILMUAN UNGGUL DI CAKRAWALA ILMU</p>
                <br />
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PENGEMBANG KARYA CIPTA ILMIAH</p>
                <p>RUANG OLAH ILMU DAN PENGETAHUAN</p>
                <br />
                <p>DENGAN SMANGAT MELANGKAH LEBIH MAJU</p>
                <p>DI ERA TEKNOLOGI INFORMASI</p>
                <p>TANAMKAN IMAN TAQWA DAN BUDAYA</p>
                <p>WUJUDKAN INSAN BERBUDI LUHUR</p>
                <br />
                <p>UNIVERSITAS PUTRA INDONESIA YPTK PADANG</p>
                <p>WADAH PENGABDIAN MENGAMALKAN ILMU</p>
                <p>DEMI TANAH AIR NUSA BANGSA JAYA</p>
            </div>
        </div>
      </section>
    </div>
  );
};

export default MarsUpiYptk;
