import React from "react";
import Slider from "react-slick";
import "./Header.css";

const CustomPrevArrow = ({ onClick }) => {
  return (
    <button className="slick-arrow slick-prev" onClick={onClick}>
      &#8592; {/* Ikon panah kiri */}
    </button>
  );
};

const CustomNextArrow = ({ onClick }) => {
  return (
    <button className="slick-arrow slick-next" onClick={onClick}>
      &#8594; {/* Ikon panah kanan */}
    </button>
  );
};

const Header = () => {

  // Konfigurasi Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    prevArrow: <CustomPrevArrow />, // Menggunakan panah kustom
    nextArrow: <CustomNextArrow />, // Menggunakan panah kustom
  };

  const handleButtonClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="header-container">
      <video className="background-video" autoPlay loop muted>
        <source src="/VideoProfileKampusUPIYPTK.mp4" type="video/mp4" />
      </video>

       {/* Overlay Hitam hanya untuk header-container */}
       <div className="overlay"></div> 

      <Slider {...settings}>
        <div className="hero-slide">
          <div className="hero-content">
            <p>UNIVERSITAS PUTRA INDONESIA (UPI) YPTK</p>
            <h1>#1 Kampus Swasta Terbaik di Sumatera Barat</h1>
            <p style={{ marginBottom:'30px'}}>Dengan 6 fakultas unggulan, bersama UPI YPTK Padang, Melangkah Lebih Maju!</p>
            <button
              onClick={() => handleButtonClick("https://spmb.upiyptk.ac.id/")}
              className="cta-button"
            >
              Bergabung Bersama UPI YPTK
            </button>
          </div>
          <div className="hero-background">
            {/* <img src="/tes.jpg" alt="Hero 1" /> */}
          </div>
        </div>

      </Slider>
    </div>
  );
};

export default Header;
