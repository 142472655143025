import React, { useState } from "react";
import "./PimpinanKampus.css";

const pimpinanData = [
  {
    nama: "Prof. Dr. H. Sarjon Defit, S.Kom, MSc",
    jabatan: "Rektor",
    foto: "/pimpinan/Rektor.jpg",
    detail: {
      ttl: "Padang Sibusuk / 07 Agustus 1970",
      nidn: "1007087002",
      jenisKelamin: "Laki-laki",
      agama: "Islam",
      email: "sarjond@yahoo.co.uk",
      fakultas: "Ilmu Komputer / Sistem Informasi",
      pekerjaan: "Dosen / Rektor UPI-YPTK Padang",
      pangkat: "Guru Besar / IVc",
      riwayatPendidikan: [
        "SD Inpres I 3/76 Padang Sibusuk, tamat tahun 1983",
        "Madarasah Tsanawiyah Negeri Padang Sibusuk, tamat tahun 1986",
        "Madarasah Aliyah Negeri Koto Baru Padang Panjang, tamat tahun 1989",
        "STMIK “YPTK” Padang, tamat tahun 1993",
        "Universiti Teknologi Malaysia (S2), tamat tahun 1998",
        "Universiti Teknologi Malaysia (S3), tamat tahun 2003",
      ],
    },
  },
  {
    nama: "Dr. Ir. Sumijan, M.Sc",
    jabatan: "Pembantu Rektor I",
    foto: "/pimpinan/wr-1.jpg",
    detail: {
      nidn: "0005076607",
      jenisKelamin: "Laki-laki",
      jabatanFungsional: "Lektor",
      identitas: "196605071994031004",
      scopusID: "57189371499",
      orcidID: "0000-0002-9932-4325",
      researcherID: "I-9167-2017",
      googleScholar: "XxDV_WsAAAAJ",
      researchGate:
        "https://www.researchgate.net/profile/Sumijan_Sumijan",
      mataKuliah: [
        "Pengolahan Citra Digital",
        "Audit Teknologi Informasi",
        "Perencanaan Infrastruktur",
        "Computer Vision",
        "Database Lanjutan",
        "Data Mining",
        "Rekayasa Sistem Informasi",
        "Organisasi Sistem Komputer",
        "Object Oriented Programming",
      ],
    },
  },
  {
    nama: "Dr. Hanna Pratiwi, SE, MM",
    jabatan: "Pembantu Rektor II",
    foto: "/pimpinan/wr-2.jpg",
  },
  {
    nama: "Dr. Elvis Wandi, SE, MM, Ak, CA",
    jabatan: "Pembantu Rektor III",
    foto: "/pimpinan/wr-3.jpg",
  },
  {
    nama: "Dr. Jhon Veri, S.Kom, MM, M.Kom",
    jabatan: "Pembantu Rektor IV",
    foto: "/pimpinan/wr-4.jpg",
  },
];

function PimpinanKampus() {
  const [selectedPimpinan, setSelectedPimpinan] = useState(pimpinanData[0]);

  return (
    <div className="pimpinan-container">
        {/* Header Section */}
        <header className="header-section">
            <div className="header-content">
            <h2>PIMPINAN UPI YPTK</h2>
            <hr className="header-line" />
            </div>
        </header>

        <div className="pimpinan-kampus">
            <div className="pimpinan-list">
                {pimpinanData.map((pimpinan, index) => (
                <div
                    key={index}
                    className={`pimpinan-item ${
                    selectedPimpinan.nama === pimpinan.nama ? "active" : ""
                    }`}
                    onClick={() => setSelectedPimpinan(pimpinan)}
                >
                    <h3>{pimpinan.nama}</h3>
                    <h5>{pimpinan.jabatan}</h5>
                </div>
                ))}
            </div>

            <div className="pimpinan-detail">
                <img src={selectedPimpinan.foto} alt={selectedPimpinan.nama} className="pimpinan-foto" />
                <h2>{selectedPimpinan.nama}</h2>
                <h5>{selectedPimpinan.jabatan}</h5>

                {selectedPimpinan.detail && (
                <>
                    <table>
                    <tbody>
                        <tr>
                        <td><strong>NIDN</strong></td>
                        <td>{selectedPimpinan.detail.nidn}</td>
                        </tr>
                        <tr>
                        <td><strong>Email</strong></td>
                        <td>{selectedPimpinan.detail.email}</td>
                        </tr>
                        <tr>
                        <td><strong>Tempat/ tgl. lahir</strong></td>
                        <td>{selectedPimpinan.detail.ttl}</td>
                        </tr>
                        <tr>
                        <td><strong>Pangkat/Golongan</strong></td>
                        <td>{selectedPimpinan.detail.pangkat}</td>
                        </tr>
                        <tr>
                        <td><strong>Jenis Kelamin</strong></td>
                        <td>{selectedPimpinan.detail.jenisKelamin}</td>
                        </tr>
                        <tr>
                        <td><strong>Fakultas</strong></td>
                        <td>{selectedPimpinan.detail.fakultas}</td>
                        </tr>

                        <tr>
                        <td colSpan="2"><strong>Riwayat Pendidikan</strong></td>
                        </tr>
                        {selectedPimpinan.detail.riwayatPendidikan?.map((item, idx) => (
                        <tr key={idx}>
                            <td colSpan="2" className="right-align">{item}</td> {/* Isi di sebelah kanan */}
                        </tr>
                        ))}

                        <tr>
                        <td colSpan="2"><strong>Mata Kuliah Diampu</strong></td>
                        </tr>
                        {selectedPimpinan.detail.mataKuliah?.map((item, idx) => (
                        <tr key={idx}>
                            <td colSpan="2" className="right-align">{item}</td> {/* Isi di sebelah kanan */}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </>
                )}


            </div>
        </div>

    </div>
  );
}

export default PimpinanKampus;
