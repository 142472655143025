import React, { useState } from 'react';
import './StrukturOrganisasi.css';

const StrukturOrganisasi = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="struktur-organisasi-container">
      {/* Header Section */}
      <header className="header-section">
        <div className="header-content">
          <h2>STRUKTUR ORGANISASI UPI YPTK</h2>
          <hr className="header-line" />
        </div>
      </header>

      {/* Image Section */}
      <div className="image-container">
        <img 
          src="/struktur/struktur-organisasi.jpg" 
          alt="Struktur Organisasi" 
          className="struktur-image" 
          onClick={openModal}  // Menambahkan event untuk membuka modal saat gambar diklik
        />
      </div>

      {/* Modal Pop-up */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}> {/* Prevent click event bubbling */}
            <span className="close-btn" onClick={closeModal}>&times;</span>
            <img 
              src="/struktur/struktur-organisasi.jpg" 
              alt="Struktur Organisasi" 
              className="modal-image" 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StrukturOrganisasi;
