import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './AllPost.css';

const AllPost = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    axios
      .get(`https://web.infoupiyptk.com/api/post/all-post.php?limit=9&page=${page}`)
      .then((response) => {
        setPosts(response.data.posts);
        setTotalPages(response.data.totalPages);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError('Failed to load posts');
        setLoading(false);
      });
  }, [page]);

  const truncateText = (text, length = 100) => {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('id-ID', options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="all-posts">
      <Container>
        <Row>
          {posts.map((post) => (
            <Col md={4} key={post.id} className="mb-4">
              <div className="post-card">
                <div>
                <img
                  src={`https://web.infoupiyptk.com/api/post/${post.featured_image}`}
                  alt={post.title}
                  className="post-image"
                />
                <div className="post-date" >{formatDate(post.created_at)}</div>
                <h2 className="post-title-all">
                  {truncateText(post.title, 70)}
                </h2>
                <p className="post-excerpt">
                  {truncateText(post.excerpt, 100)}
                </p>
                </div>

                <div>
                <Link to={`/post/${post.url}`} className="read-more-button">
                  Selengkapnya
                </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          onClick={() => setPage(page > 1 ? page - 1 : 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>Page {page} of {totalPages}</span>
        <button
          onClick={() => setPage(page < totalPages ? page + 1 : totalPages)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AllPost;
