import React from "react";
import "./SejarahUpiYptk.css"; // Tambahkan file CSS

const SejarahUpiYptk = () => {
  return (
    <div className="sejarah-container">
      {/* Header Section */}
      <header className="header-section">
        <div className="header-content">
          <h2>Sejarah UPI YPTK</h2>
          <hr className="header-line" />
        </div>
      </header>

      {/* Content Section */}
      <section className="content-section">
        <div className="image-column">
          <div className="image-wrapper">
            <img
              src="/sejarah-upi/Bapak-Ibu-Yayasan.jpg"
              alt="Pendiri UPI YPTK"
              className="content-image"
            />
            <p className="image-description">
              <strong>H. Herman Nawas & Dr.Hj. Zerni Melmusi, MM, Ak, CA</strong> - Pencetus Ide dan Pendiri UPI YPTK Padang
            </p>
          </div>
        </div>
        <div className="text-column">
          <p>
            Seiring dengan pembangunan nasional dalam era globalisasi, kita memerlukan 
            sumber daya manusia yang potensial dalam menguasai ilmu pengetahuan dan teknologi. 
            Dengan menggunakan IPTEK diharapkan bangsa Indonesia mampu bersaing di tingkat 
            Internasional sehingga dapat berdiri sejajar dengan bangsa-bangsa lain.
          </p>
          <p>
            Yayasan Perguruan Tinggi Komputer, Padang, yang berdiri pada tahun 1985 dan sampai 
            sekarang telah membina empat perguruan tinggi Program Strata 1 (S1) dan Strata 2 (S2), 
            serta Program Diploma 3 (DIII). Dengan tekat, usaha dan Rahmat Allah, diawal 
            Millenium III ini, YPTK mengembangkan diri menjadi Universitas Putra Indonesia 
            “YPTK” Padang, dengan SK Mendinas RI, No. 29/D/0/2001, turut menyumbangkan bhakti 
            untuk mencerdaskan bangsa, dengan 5 fakultas & Program Pasca Sarjana.
          </p>
        </div>
      </section>
    </div>
  );
};

export default SejarahUpiYptk;
