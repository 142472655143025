import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import './Beasiswa.css';

const Beasiswa = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeOverlay = () => {
    setSelectedImage(null);
  };

  const images = [
    "/beasiswa-asset/PMB-Januari-B1.jpg",
    "/beasiswa-asset/PMB-Januari-B2.jpg",
  ];

  return (
    <div className="beasiswa">
        {/* Header Section */}
        <header className="header-section">
            <div className="header-content">
                <h2>Beasiswa UPI YPTK</h2>
                <hr className="header-line" />
            </div>
            </header>

      <Container className="mt-4 gambar-beasiswa">
        <Row>
          {images.map((image, index) => (
            <Col md="3" key={index} className="mb-4">
              <img
                src={image}                // Path relatif
                alt={`Beasiswa ${index + 1}`}
                className="img-fluid"
                style={{ cursor: "pointer" }}
                onClick={() => handleImageClick(`/${image}`)}  // Ketika gambar diklik
              />
            </Col>
          ))}
        </Row>
      </Container>

      {/* Overlay untuk gambar yang dipilih */}
      {selectedImage && (
        <div className="overlay" onClick={closeOverlay}>
          <img
            src={selectedImage}
            alt="Preview"
            className="overlay-image"
          />
        </div>
      )}
    </div>
  );
};

export default Beasiswa;
