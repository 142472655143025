import React, { useState } from "react";
import { Table, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import './AkreditasiKampus.css';

const akreditasiData = [
    { program: "UPI YPTK", jenjang: "Universitas", tahun: "2022 - 2027", link: "https://upiyptk.ac.id/akreditasi/Sertifikat-AIPT-2022.pdf" },
    { program: "UPI YPTK (Legalisisir)", jenjang: "Universitas", tahun: "2022 - 2027", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20AIPT%202022-2027.jpg" },
    { program: "UPI YPTK (Lamemba)", jenjang: "Universitas", tahun: "2022 - 2027", link: "https://upiyptk.ac.id/akreditasi/Sertifikat-Akreditasi-LAMEMBA-Universitas-Putra-Indonesia-YPTK-Sarjana-Akuntansi-2023.pdf" },
    { program: "UPI YPTK", jenjang: "Universitas", tahun: "2017 - 2022", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20Akreditasi%20UPI-YPTK.png" },
    { program: "Akuntansi", jenjang: "D3", tahun: "2002", link: "https://upiyptk.ac.id/akreditasi/Akreditasi%20D3-Akuntansi%202002.pdf" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2006", link: "https://upiyptk.ac.id/akreditasi/AKUNTANSI-2006.jpeg" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2012", link: "https://upiyptk.ac.id/akreditasi/AKUNTANSI-2012.jpeg" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2017", link: "https://upiyptk.ac.id/akreditasi/AKUNTANSI-2017.jpeg" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2017 - 2022", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20BAN-PT%20Prodi%20S1-Akuntansi.png" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2022 - 2027", link: "https://upiyptk.ac.id/akreditasi/Sertifikat-Akreditasi-LAMEMBA-Universitas-Putra-Indonesia-YPTK-Sarjana-Akuntansi-2023.pdf" },
    { program: "Manajemen", jenjang: "S1", tahun: "2006", link: "https://upiyptk.ac.id/akreditasi/MANAJEMEN-2006.jpeg" },
    { program: "Manajemen", jenjang: "S1", tahun: "2011 - 2016", link: "https://upiyptk.ac.id/akreditasi/MANAJEMEN-2011.jpeg" },
    { program: "Manajemen", jenjang: "S1", tahun: "2021 - 2026", link: "https://upiyptk.ac.id/akreditasi/S1-Manajemen-2021-2026.pdf" },
    { program: "Manajemen Informatika", jenjang: "D3", tahun: "2021 - 2026", link: "https://upiyptk.ac.id/akreditasi/Sertifikat-Akreditasi-Prodi-D3-Manajemen-Informatika-2021-2026.pdf" },
    { program: "Magister Komputer", jenjang: "S2", tahun: "2018 - 2023", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MKOM%202018.pdf" },
    { program: "Teknik Informatika", jenjang: "S1", tahun: "2016 - 2021", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITAS%20IF%202016.pdf" },
    { program: "Teknik Sipil", jenjang: "S1", tahun: "2021 - 2026", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20Akreditasi%20Teknik%20Sipil%202021-2026.pdf" },
    { program: "Teknik Mesin", jenjang: "S1", tahun: "2020 - 2025", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20Akreditasi%20Teknik%20Mesin%202020-2025.pdf" },
    { program: "Desain Komunikasi Visual", jenjang: "S1", tahun: "2019 - 2024", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20DKV%202019.pdf" },
    { program: "Magister Manajemen", jenjang: "S2", tahun: "2020 - 2025", link: "https://upiyptk.ac.id/akreditasi/Sertifikat%20Akreditasi%20MM%202020.pdf" },
    { program: "Teknik Elektro", jenjang: "S1", tahun: "2019 - 2024", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20TE%202019.pdf" },
    { program: "Psikologi", jenjang: "S1", tahun: "2018 - 2023", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20PSIKOLOGI%202018.pdf" },
    { program: "Akuntansi", jenjang: "D3", tahun: "2019 - 2024", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20D3%20AKUNTANSI%202019.pdf" },
    { program: "Manajemen Informatika", jenjang: "D3", tahun: "2021 - 2026", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MI%202021.pdf" },
    { program: "Sistem Informasi", jenjang: "S1", tahun: "2020 - 2025", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20SI%202020.pdf" },
    { program: "Teknologi Informasi", jenjang: "S1", tahun: "2019 - 2024", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20TI%202019.pdf" },
    { program: "Magister Psikologi", jenjang: "S2", tahun: "2017 - 2022", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MP%202017.pdf" },
    { program: "Desain Komunikasi Visual", jenjang: "S1", tahun: "2018 - 2023", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20DKV%202018.pdf" },
    { program: "Manajemen", jenjang: "S1", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MANAJEMEN%202023.pdf" },
    { program: "Teknik Sipil", jenjang: "S1", tahun: "2022 - 2027", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20TEKNIK%20SIPIL%202022.pdf" },
    { program: "Akuntansi", jenjang: "S1", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20AKUNTANSI%202023.pdf" },
    { program: "Magister Manajemen", jenjang: "S2", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MM%202023.pdf" },
    { program: "Sistem Informasi", jenjang: "S1", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20SI%202023.pdf" },
    { program: "Manajemen Informatika", jenjang: "S1", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20MI%202023.pdf" },
    { program: "Teknik Informatika", jenjang: "S1", tahun: "2023 - 2028", link: "https://upiyptk.ac.id/akreditasi/SERTIFIKAT%20AKREDITASI%20TI%202023.pdf" }
  ];

  const AkreditasiKampus = () => {
    const [searchText, setSearchText] = useState("");
    const [selectedJenjang, setSelectedJenjang] = useState("All");
    const [dropdownOpen, setDropdownOpen] = useState(false);
  
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  
    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
    };
  
    const handleJenjangChange = (jenjang) => {
      setSelectedJenjang(jenjang);
    };
  
    // Filter data berdasarkan pencarian dan jenjang yang dipilih
    const filteredData = akreditasiData.filter((data) => {
      const matchesSearchText = data.program.toLowerCase().includes(searchText.toLowerCase());
      const matchesJenjang = selectedJenjang === "All" || data.jenjang === selectedJenjang;
      return matchesSearchText && matchesJenjang;
    });
  
    return (
        <div className="akreditasi-kampus">
            {/* Header Section */}
            <header className="header-section">
            <div className="header-content">
                <h2>Akreditasi UPI YPTK</h2>
                <hr className="header-line" />
            </div>
            </header>

            <div className="container mt-4">
                {/* Filter Dropdown */}
                <div className="mb-3">
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle caret color="primary" className="d-flex align-items-center">
                    {selectedJenjang}
                    </DropdownToggle>
                    <DropdownMenu>
                    <DropdownItem onClick={() => handleJenjangChange("All")}>All</DropdownItem>
                    <DropdownItem onClick={() => handleJenjangChange("Universitas")}>Universitas</DropdownItem>
                    <DropdownItem onClick={() => handleJenjangChange("D3")}>D3</DropdownItem>
                    <DropdownItem onClick={() => handleJenjangChange("S1")}>Sarjana / S1</DropdownItem>
                    <DropdownItem onClick={() => handleJenjangChange("S2")}>Magister / S2</DropdownItem>
                    <DropdownItem onClick={() => handleJenjangChange("S3")}>Doktor / S3</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                </div>
        
                {/* Filter Cari Program */}
                <div className="mb-3">
                <Input
                    type="text"
                    placeholder="Cari Program Studi..."
                    value={searchText}
                    onChange={handleSearchChange}
                    className="d-flex align-items-center"
                />
                </div>
        
                {/* Tabel Data Akreditasi */}
                <Table striped>
                <thead>
                    <tr>
                    <th className="text-center align-middle">No.</th>
                    <th className="align-middle">Program</th>
                    <th className="align-middle">Jenjang</th>
                    <th className="align-middle">Tahun</th>
                    <th className="text-center align-middle">Unduh</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((data, index) => (
                    <tr key={index}>
                        <td className="text-center align-middle">{index + 1}</td>
                        <td className="align-middle">{data.program}</td>
                        <td className="align-middle">{data.jenjang}</td>
                        <td className="align-middle">{data.tahun}</td>
                        <td className="text-center  align-middle">
                        <a href={data.link} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                            Unduh
                        </a>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </Table>
            </div>
        </div>
    );
  };
  
  export default AkreditasiKampus;