import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Highlight.css';

const Highlight = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the 3 most recent posts
    axios
      .get('https://web.infoupiyptk.com/api/post/all-post.php?limit=3&page=1')
      .then((response) => {
        console.log('Received data:', response.data);  // Cek data yang diterima

        // Akses posts dari response.data.posts
        if (Array.isArray(response.data.posts)) {
          setPosts(response.data.posts);  // Menyimpan data posts
        } else {
          setPosts([]);  // Jika data.posts tidak array, set posts ke array kosong
        }

        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError('Failed to load posts');
        setLoading(false);
      });
  }, []);

  // Function to truncate text (title and excerpt)
  const truncateText = (text, length = 100) => {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
  };

  // Function to format the date as DD November YYYY
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('id-ID', options);
  };

  // Loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Error state
  if (error) {
    return <div>{error}</div>;
  }

  // If posts is empty
  if (posts.length === 0) {
    return <div>No posts available</div>;
  }

  return (
    <div className="highlight">
      <div className="highlight-list">
        {posts.map((post) => (
          <div key={post.id} className="highlight-card">
            <img
              src={`https://web.infoupiyptk.com/api/post/${post.featured_image}`}
              alt={post.title}
              className="highlight-image"
            />
            {/* Date above the title */}
            <div className="post-date">{formatDate(post.created_at)}</div>
            <h2 className="highlight-title">
              {truncateText(post.title, 70)} {/* Truncate title to 70 characters */}
            </h2>
            <p className="highlight-excerpt">
              {truncateText(post.excerpt, 200)} {/* Truncate excerpt to 200 characters */}
            </p>
            <Link to={`/post/${post.url}`} className="highlight-read-more">
              Selengkapnya
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Highlight;
