import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import './Kontak.css';  // Mengimpor CSS yang sudah disiapkan

const Kontak = () => {
  return (
    <div className="kontak">
        {/* Header Section */}
        <header className="header-section">
            <div className="header-content">
            <h2>Kontak</h2>
            <hr className="header-line" />
            </div>
        </header>


      <Container className="mt-5">
        {/* Google Maps Embed */}
        <Row>
          <Col>
            <div className="google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.260352928364!2d100.3940383756883!3d-0.9582317353570601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2fd4b982fd776c89%3A0x4e9e1c8dcc9c55fb!2sUniversitas%20Putra%20Indonesia%20%22YPTK%22!5e0!3m2!1sid!2sid!4v1733892085954!5m2!1sid!2sid"
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="Peta Lokasi UPI YPTK Padang" // Menambahkan atribut title
              ></iframe>
            </div>
          </Col>
        </Row>

        <Row className="mb-5 detail-kontak">
          <Col md="4" className="mb-4 kontak-kiri">
            <h4>Alamat</h4>
            <hr />
            <p>
              Jl. Raya Lubuk Begalung, Lubuk Begalung Nan XX, Kec. Lubuk Begalung,
              Kota Padang, Sumatera Barat 25145
            </p>

            <br />
            <h4>Kontak</h4>
            <hr />
            <p><strong>Tel.</strong> (0751) 776666</p>
            <p><strong>WA</strong> +628 11 666 0165</p>
            <p><strong>Mail</strong> admin@upiyptk.ac.id</p>
          </Col>

          <Col md="8" className="mb-4 kontak-kanan">
                <Row className="justify-content-end flex-wrap">
                    <Col xs="6" sm="4" md="2" className="mb-3">
                    <Button
                        color="primary"
                        className="social-button"
                        href="https://www.instagram.com/upiyptk_padang/"
                        target="_blank"
                    >
                        <i className="fab fa-instagram"></i> Instagram
                    </Button>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="mb-3">
                    <Button
                        color="primary"
                        className="social-button"
                        href="https://www.facebook.com/upi.yptk/"
                        target="_blank"
                    >
                        <i className="fab fa-facebook"></i> Facebook
                    </Button>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="mb-3">
                    <Button
                        color="primary"
                        className="social-button"
                        href="https://x.com/i/flow/login?redirect_after_login=%2FUpiYPTK_Pdg"
                        target="_blank"
                    >
                        <i className="fab fa-twitter"></i> Twitter
                    </Button>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="mb-3">
                    <Button
                        color="primary"
                        className="social-button"
                        href="https://www.youtube.com/@universitasputraindonesiay5026"
                        target="_blank"
                    >
                        <i className="fab fa-youtube"></i> Youtube
                    </Button>
                    </Col>
                    <Col xs="6" sm="4" md="2" className="mb-3">
                    <Button
                        color="primary"
                        className="social-button"
                        href="https://www.tiktok.com/@upiyptkpadang"
                        target="_blank"
                    >
                        <i className="fab fa-tiktok"></i> Tiktok
                    </Button>
                    </Col>
                    
                </Row>
            </Col>


        </Row>

      </Container>
    </div>
  );
};

export default Kontak;
