import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import './NavbarHeader.css'; 

const NavbarHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // Mengatur dropdown yang aktif

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownEnter = (menu) => {
    setActiveDropdown(menu); // Set dropdown yang aktif
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null); // Tutup semua dropdown ketika cursor keluar
  };

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className={`navbar-header ${scrolled ? 'scrolled' : ''}`}
    >
      <Container>
        <Navbar.Brand href="/">
          <img src="/logo-upi-dan-yayasan.png" alt="UPI YPTK Logo" className="navbar-logo" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            {/* PROFILE Dropdown */}
            <NavDropdown
              title="PROFILE"
              id="profile-dropdown"
              show={activeDropdown === 'profile'}
              onMouseEnter={() => handleDropdownEnter('profile')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="/visi-misi"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Visi dan Misi</NavDropdown.Item>
              <NavDropdown.Item href="/sejarah-upi-yptk"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Sejarah</NavDropdown.Item>
              <NavDropdown.Item href="/pimpinan-kampus"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Pimpinan Kampus</NavDropdown.Item>
              <NavDropdown.Item href="/struktur-organisasi-kampus"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Struktur Organisasi</NavDropdown.Item>
              <NavDropdown.Item href="/mars-upi-yptk"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Mars UPI YPTK</NavDropdown.Item>
              <NavDropdown.Item href="/fasilitas-upi-yptk"
              onMouseEnter={() => handleDropdownEnter('profile')} 
              onMouseLeave={handleDropdownLeave}
              >Fasilitas</NavDropdown.Item>
            </NavDropdown>

            {/* FAKULTAS Dropdown */}
            <NavDropdown
              title="FAKULTAS"
              id="fakultas-dropdown"
              show={activeDropdown === 'fakultas'}
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="https://filkom.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Ilmu Komputer</NavDropdown.Item>
              <NavDropdown.Item href="https://fdkv.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Desain Komunikasi Visual</NavDropdown.Item>
              <NavDropdown.Item href="https://fekon.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Ekonomi dan Bisnis</NavDropdown.Item>
              <NavDropdown.Item href="https://fpsi.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Psikologi</NavDropdown.Item>
              <NavDropdown.Item href="https://fkip.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Keguruan Ilmu Pendidikan</NavDropdown.Item>
              <NavDropdown.Item href="https://teknik.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('fakultas')}
              onMouseLeave={handleDropdownLeave}
              >Teknik</NavDropdown.Item>
            </NavDropdown>

            {/* PORTAL DIGITAL Dropdown */}
            <NavDropdown
              title="PORTAL DIGITAL"
              id="portal-dropdown"
              show={activeDropdown === 'portal'}
              onMouseEnter={() => handleDropdownEnter('portal')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="https://sisfo.upiyptk.ac.id/" target="_blank"
              onMouseEnter={() => handleDropdownEnter('portal')}
              onMouseLeave={handleDropdownLeave}
              >
                Sisfo UPI
              </NavDropdown.Item>
              <NavDropdown.Item href="http://elib.upiyptk.ac.id/" target="_blank"
              onMouseEnter={() => handleDropdownEnter('portal')}
              onMouseLeave={handleDropdownLeave}
              >
                E-Library
              </NavDropdown.Item>
              <NavDropdown.Item href="http://elearning.upiyptk.ac.id/" target="_blank"
              onMouseEnter={() => handleDropdownEnter('portal')}
              onMouseLeave={handleDropdownLeave}
              >
                E-Learning
              </NavDropdown.Item>
              <NavDropdown.Item href="http://lpmi.upiyptk.ac.id/home" target="_blank"
              onMouseEnter={() => handleDropdownEnter('portal')}
              onMouseLeave={handleDropdownLeave}
              >
                LPMI
              </NavDropdown.Item>
                  
                  <h6 style={{padding:'15px 30px 0px 30px'}}
                  onMouseEnter={() => handleDropdownEnter('portal')}
                  onMouseLeave={handleDropdownLeave}
                  ><strong>LPPM
                    <NavDropdown.Item className="child" style={{fontSize:'14px', borderTop:'none', borderBottom:'none', padding:'10px 10px', borderRadius:'5px', marginTop:'10px'}} href="https://upiyptk.ac.id/pedoman-mahasiswa/Proposal_PenelitianTemplate.doc" target="_blank"
                    onMouseEnter={() => handleDropdownEnter('portal')}
                    onMouseLeave={handleDropdownLeave}
                    >
                    Pedoman Proposal Penelitian
                    </NavDropdown.Item>
                    <NavDropdown.Item className="child" style={{fontSize:'14px', borderTop:'none', borderBottom:'none', padding:'10px 10px', borderRadius:'5px'}} href="https://upiyptk.ac.id/pedoman-mahasiswa/Proposal_Pengabdian(Template).doc" target="_blank"
                    onMouseEnter={() => handleDropdownEnter('portal')}
                    onMouseLeave={handleDropdownLeave}
                    >
                    Pedoman Proposal Pengabdian
                    </NavDropdown.Item>
                  </strong></h6>
     
            </NavDropdown>

            {/* AKADEMIK Dropdown */}
            <NavDropdown
              title="AKADEMIK"
              id="akademik-dropdown"
              show={activeDropdown === 'akademik'}
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="/akreditasi-kampus" 
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
              >Akreditasi Kampus</NavDropdown.Item>
              <NavDropdown.Item href="https://mbkm.upiyptk.ac.id/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
              >MBKM</NavDropdown.Item>
              <NavDropdown.Item href="/pkm-upi-yptk" 
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
              >Program Kreatifitas Mahasiswa (PKM)</NavDropdown.Item>
              <NavDropdown.Item href="/beasiswa"
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
              >Beasiswa</NavDropdown.Item>
              <NavDropdown.Item href="#" 
              onMouseEnter={() => handleDropdownEnter('akademik')}
              onMouseLeave={handleDropdownLeave}
              >Tracer Studi</NavDropdown.Item>
            </NavDropdown>

            {/* JURNAL Dropdown */}
            <NavDropdown
              title="JURNAL"
              id="jurnal-dropdown"
              show={activeDropdown === 'jurnal'}
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="https://jbe-upiyptk.org/ojs/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Bisnis dan Ekonomi</NavDropdown.Item>
              <NavDropdown.Item href="https://jcivil-upiyptk.org/ojs/index.php/jcivil" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Civil Engineering</NavDropdown.Item>
              <NavDropdown.Item href="https://jcsitech-upiyptk.org/ojs/index.php/jcsitech" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Komputer dan Teknologi</NavDropdown.Item>
              <NavDropdown.Item href="https://jitekin-upiyptk.org/ojs/index.php/Teknologi" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >JITEKIN</NavDropdown.Item>
              <NavDropdown.Item href="https://jkomtekinfo.org/ojs/index.php/komtekinfo" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Komtek Info</NavDropdown.Item>
              <NavDropdown.Item href="https://jman-upiyptk.org/ojs/index.php/ekobistek" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >EKOBISTEK</NavDropdown.Item>
              <NavDropdown.Item href="https://jmi-upiyptk.org/ojs/index.php/jmi" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Majalah Ilmiah</NavDropdown.Item>
              <NavDropdown.Item href="https://jpsy165.org/ojs/index.php/jpsy165" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Psyche 165 Journal</NavDropdown.Item>
              <NavDropdown.Item href="https://judikatif-upiyptk.org/ojs/index.php/judikatif" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Judikatif</NavDropdown.Item>
              <NavDropdown.Item href="https://jlesson-upiyptk.org/ojs/" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >Lesson</NavDropdown.Item>
              <NavDropdown.Item href="https://jpti-upiyptk.org/ojs/index.php/jpti" target='_blank'
              onMouseEnter={() => handleDropdownEnter('jurnal')}
              onMouseLeave={handleDropdownLeave}
              >PTI</NavDropdown.Item>
            </NavDropdown>

            {/* INFORMASI Dropdown */}
            <NavDropdown
              title="INFORMASI"
              id="informasi-dropdown"
              show={activeDropdown === 'informasi'}
              onMouseEnter={() => handleDropdownEnter('informasi')}
              onMouseLeave={handleDropdownLeave}
            >
              <NavDropdown.Item href="/newsroom" 
              onMouseEnter={() => handleDropdownEnter('informasi')}
              onMouseLeave={handleDropdownLeave}
              >Newsroom</NavDropdown.Item>
              <NavDropdown.Item href="/kontak" 
              onMouseEnter={() => handleDropdownEnter('informasi')}
              onMouseLeave={handleDropdownLeave}
              >Kontak</NavDropdown.Item>
            </NavDropdown>

            {/* PENDAFTARAN Dropdown */}
            <NavDropdown
              title="PENDAFTARAN"
              id="pendaftaran-dropdown"
              show={activeDropdown === 'pendaftaran'}
              onMouseEnter={() => handleDropdownEnter('pendaftaran')}
              onMouseLeave={handleDropdownLeave}

            >
              <NavDropdown.Item href="https://spmb.upiyptk.ac.id/" target='_blank' 
              onMouseEnter={() => handleDropdownEnter('pendaftaran')}
              onMouseLeave={handleDropdownLeave}
              >D3 dan S1</NavDropdown.Item>
              <NavDropdown.Item href="http://pascasarjana.upiyptk.ac.id/daftar/login" target='_blank'
              onMouseEnter={() => handleDropdownEnter('pendaftaran')}
              onMouseLeave={handleDropdownLeave}
              >Magister (S2)</NavDropdown.Item>
              <NavDropdown.Item href="https://psdti.upiyptk.ac.id/login" target='_blank'
              onMouseEnter={() => handleDropdownEnter('pendaftaran')}
              onMouseLeave={handleDropdownLeave}
              >Doktor (S3)</NavDropdown.Item>
            </NavDropdown>


          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarHeader;
