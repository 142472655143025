import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";

const ProgramKreatifitasMahasiswa = () => {
  return (
    <div className="pkm-upi">
            {/* Header Section */}
            <header className="header-section">
            <div className="header-content">
                <h2>PROGRAM KREATIVITAS MAHASISWA (PKM)</h2>
                <hr className="header-line" />
            </div>
            </header>

      <Container className="mt-4" style={{ margin:'50px auto'}}>
        <Row>
          <Col>
          <Card style={{border:'none'}}>
                <CardBody>
                  <CardTitle tag="h6"><strong>Rabu, 04 Desember 2019</strong></CardTitle>
                  <CardText>
                    Diberitahukan kepada seluruh mahasiswa/wi Universitas Putra Indonesia YPTK:
                  </CardText>
                  <CardText>
                    Direktorat Jenderal Pembelajaran dan Kemahasiswaan, Kementerian Pendidikan dan Kebudayaan akan
                    menyelenggarakan Program Kreativitas Mahasiswa (PKM) pelaksanaan tahun 2020, bersama ini kami memberi
                    kesempatan kepada mahasiswa untuk mengajukan proposal PKM 5 Bidang (PKM-P, PKM-M, PKM-K, PKM-T, PKM-KC),
                    PKM-AI, PKM-GT dan PKM-GFK pelaksanaan tahun 2020 dengan waktu penerimaan tanggal 25 November s.d 20
                    Desember 2019 pukul 23:59 WIB dengan ketentuan sebagai berikut:
                  </CardText>
                  <CardText>
                    Pedoman PKM 2020 dapat diunduh di laman:
                    <ul>
                      <li>
                        <a href="https://belmawa.ristekdikti.go.id/" target="_blank" rel="noopener noreferrer">
                          https://belmawa.ristekdikti.go.id/
                        </a>
                      </li>
                      <li>
                        <a href="https://simbelmawa.ristekdikti.go.id/" target="_blank" rel="noopener noreferrer">
                          https://simbelmawa.ristekdikti.go.id/
                        </a>
                      </li>
                    </ul>
                  </CardText>
                  <CardText>
                    Untuk info lebih lanjut silahkan hubungi LPPM (F8) atau PD 3 masing-masing Fakultas.
                  </CardText>
                </CardBody>
              </Card>
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
  );
};

export default ProgramKreatifitasMahasiswa;
