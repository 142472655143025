import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import './FasilitasUpiYptk.css';

const FasilitasUpiYptk = () => {
  const initialPhotos = [
    '/foto-fasilitas/Fasilitas-Student-Center.jpg',
    '/foto-fasilitas/rekorat.jpg',
    '/foto-fasilitas/gedung-kuliah-a.jpg',
    '/foto-fasilitas/Fakultask-teknik-1.jpg',

    '/foto-fasilitas/fakultas-psikologi.jpg',
    '/foto-fasilitas/Fasilitas-masjid.jpg',
    '/foto-fasilitas/gallery-robotik-upi-yptk.jpg',
    '/foto-fasilitas/labor-teknik.jpg',

    '/foto-fasilitas/Fasilitas-Labor-komputer.jpg',
    '/foto-fasilitas/gallery-upi-yptk-dkv.jpg',
    '/foto-fasilitas/lab psikologi upi yptk.webp',
    '/foto-fasilitas/Fasilitas-Gedung-kuliah.jpg',
  ];

  const additionalPhotos = [
    '/foto-fasilitas/gedung-kuliah-2.jpg',
    '/foto-fasilitas/gedung-kuliah.jpg',
    '/foto-fasilitas/Fasilitas-Perpus2.jpg',

    '/foto-fasilitas/Fasilitas-Perpus.jpg',
    '/foto-fasilitas/upi mart.webp',
    '/foto-fasilitas/klinik.jpg',

    '/foto-fasilitas/apotek.jpg',
    '/foto-fasilitas/Fasilitas-UPI-CC.jpg',
    '/foto-fasilitas/Fasilitas-UPI-EH.jpg',

    '/foto-fasilitas/Fasilitas-basket.jpg',
    '/foto-fasilitas/Fasilitas-futsal.jpg',
    '/foto-fasilitas/Fasilitas-Klinik.jpg',
  ];

  const [photos, setPhotos] = useState(initialPhotos);
  const [showModal, setShowModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [hasMorePhotos, setHasMorePhotos] = useState(true);

  const loadMorePhotos = () => {
    if (additionalPhotos.length === 0) {
      setHasMorePhotos(false);
      return;
    }

    setPhotos((prevPhotos) => [...prevPhotos, ...additionalPhotos.slice(0, 12)]);
    additionalPhotos.splice(0, 12); // Remove the first 12 items from the additionalPhotos array
    if (additionalPhotos.length === 0) {
      setHasMorePhotos(false); // If no more photos are left
    }
  };

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPhoto(null);
  };

  return (
    <div className="fasilitas-upi">
      {/* Header Section */}
      <header className="header-section">
        <div className="header-content">
          <h2>Fasilitas UPI YPTK</h2>
          <hr className="header-line" />
        </div>
      </header>

      <Container className="foto-fasilitas">
        <Row>
          {photos.map((photo, index) => (
            <Col xs={12} sm={3} md={4} key={index} className="mb-3">
              <div
                className="photo-thumbnail"
                onClick={() => handlePhotoClick(photo)}
              >
                <img
                  src={photo}
                  alt={`Fasilitas ${index + 1}`}
                  className="img-fluid"
                />
              </div>
            </Col>
          ))}
        </Row>
        {hasMorePhotos && (
          <div className="text-center">
            <Button onClick={loadMorePhotos}>
              Load More
            </Button>
          </div>
        )}

        {/* Modal for displaying the clicked photo */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          size="lg"
          className="custom-modal"
        >
          <Modal.Body>
            {selectedPhoto && (
              <img
                src={selectedPhoto}
                alt="Selected Facility"
                className="img-fluid w-100"
              />
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default FasilitasUpiYptk;
