import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the CSS for the Quill editor

const Post = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    categories: [],
    tags: "",
    body: "",
    featuredImage: null,
    excerpt: "",
    visibility: "Public",
    url: "",
  });

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle categories change
  const handleCategoriesChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    setFormData({ ...formData, categories: selectedOptions });
  };

  // Handle file input
  const handleFileChange = (e) => {
    setFormData({ ...formData, featuredImage: e.target.files[0] });
  };

  // Generate URL from title
  const generateUrl = (title) => title.toLowerCase().replace(/\s+/g, "-");

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data for API
    const postData = new FormData();
    postData.append("title", formData.title);
    postData.append("categories", formData.categories.join(","));
    postData.append("tags", formData.tags);
    postData.append("body", formData.body);
    postData.append("featuredImage", formData.featuredImage);
    postData.append("excerpt", formData.excerpt);
    postData.append("visibility", formData.visibility);
    postData.append("url", generateUrl(formData.title));

    try {
      const response = await axios.post(
        "https://web.infoupiyptk.com/api/post/post-artikel-upi.php",
        postData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.status === 201) {
        alert("Post successfully created!");
        navigate(`/post/${response.data.url}`);
      }
    } catch (error) {
      console.error("Error creating post:", error);
      alert("Failed to create post. Please try again.");
    }
  };

  return (
    <div className="container mt-5" style={{ padding:'50px 0px'}}>
      <h2>Create a New Post</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="title">Post Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter post title"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="categories">Categories</Label>
          <Input
            type="select"
            name="categories"
            id="categories"
            onChange={handleCategoriesChange}
            value={formData.categories} // Value untuk dropdown
          >
            <option value="">Select a category</option>  {/* Placeholder option */}
            <option value="Berita">Berita</option>
            <option value="Artikel">Artikel</option>
            <option value="Informasi">Informasi</option>
            <option value="Event">Event</option>
          </Input>
        </FormGroup>


        <FormGroup>
          <Label for="tags">Tags</Label>
          <Input
            type="text"
            name="tags"
            id="tags"
            value={formData.tags}
            onChange={handleChange}
            placeholder="Enter tags separated by commas"
          />
        </FormGroup>

        <FormGroup>
          <Label for="body">Body</Label>
          <ReactQuill
            theme="snow"
            value={formData.body}
            onChange={(value) => setFormData({ ...formData, body: value })}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                [{ align: [] }],
                ["link", "blockquote"],
                ["image"],
                ["code-block"],
              ],
            }}
            style={{ height: "400px", paddingBottom:'50px' }} // Menambahkan tinggi lebih pada editor
          />
        </FormGroup>

        <FormGroup>
          <Label for="featuredImage">Featured Image</Label>
          <Input
            type="file"
            id="featuredImage"
            name="featuredImage"
            onChange={handleFileChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="excerpt">Excerpt</Label>
          <Input
            type="textarea"
            name="excerpt"
            id="excerpt"
            value={formData.excerpt}
            onChange={handleChange}
            placeholder="Enter a brief summary of the post"
            rows="3"
          />
        </FormGroup>

        <FormGroup>
          <Label for="visibility">Visibility</Label>
          <Input
            type="select"
            name="visibility"
            id="visibility"
            value={formData.visibility}
            onChange={handleChange}
          >
            <option value="Public">Public</option>
            <option value="Draft">Draft</option>
          </Input>
        </FormGroup>

        <Button color="primary" type="submit">
          Submit Post
        </Button>
      </Form>
    </div>
  );
};

export default Post;
