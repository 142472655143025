import React from "react";
import "./VisiMisi.css"; // Tambahkan file CSS

const VisiMisi = () => {
  return (
    <div className="visimisi-container">
      {/* Header Section */}
      <header className="header-section">
        <div className="header-content">
          <h2>VISI MISI UPI YPTK</h2>
          <hr className="header-line" />
        </div>
      </header>

      <div className="content-section">
        <div className="text-column1">
            <div className="visi">
                <h2>Visi</h2>
                <p>Menjadi Universitas yang unggul dan kompetitif dalam menghasilkan Sumber Daya Manusia yang berkarakter didasari Nilai-Nilai 12 Prinsip Dasar UPI-YPTK Padang pada tahun 2039</p>
            </div>
            <hr />
            <div className="misi">
                <h2>Misi</h2>
                <ol>
                    <li>Menyelenggarakan pendidikan dan pembelajaran, penelitian dan pengabdian kepada masyarakat yang unggul dan kompetitif dalam menghasilkan sumber daya manusia yang berkarakter didasari nilai-nilai 12 Prinsip Dasar UPI-YPTK Padang</li>
                    <li>Menyelenggarakan penelitian ilmu pengetahuan dan teknologi informasi serta menciptakan inovasi dan kreatifitas yang bermanfaat bagi pengembangan ilmu pengetahuan dan kesejahteraan masyarakat</li>
                    <li>Menyelenggarakan pengabdian kepada masyarakat yang berorientasi pada pemberdayaan masyarakat melalui penerapan ilmu pengetahuan, teknologi, desain, ekonomi, teknik, psikologi serta bidang kependidikan</li>
                    <li>Menyelenggarakan tata pamong yang otonom, akuntabel, dan transparan yang menjamin peningkatan kualitas berkelanjutan dengan menerapkan nilai-nilai 12 prinsip dasar UPI-YPTK Padang</li>
                </ol>
            </div>

            <div className="misi" >
                <h2>Tujuan</h2>
                <ol>
                    <li>Menjadikan Perguruan Tinggi yang unggul dan kompetitif berbasis Teknologi Informasi dan Komunikasi di masa depan yang didasari nilai-nilai 12 Prinsip Dasar UPI-YPTK Padang</li>
                    <li>Menghasilkan lulusan yang berkualitas dan relevan dengan tuntutan masa sekarang dan masa depan</li>
                    <li>Mempunyai sumber daya manusia yang capable dalam menyelenggarakan Tridharma Perguruan Tinggi</li>
                    <li>Meningkatnya kerjasama dengan berbagai pihak baik dalam maupun luar negeri dalam penyelenggaran pendidikan, penelitian dan pengabdian kepada masyarakat</li>
                    <li>Mengembangkan dan menyebarluaskan ilmu pengetahuan dan teknologi</li>
                    <li>Mengembangkan tata kelola yang capable dan profesional dalam menyelenggarakan pendidikan, penelitian dan pengabdian kepada masyarakat</li>
                </ol>
            </div>
        </div>
        <div className="text-column2">
            <div className="image">
                <img 
                src="/visi-misi-asset/foto-upi-yptk-6.jpg" 
                alt="Sasaran UPI-YPTK" 
                className="image-content" 
                />
            </div>

            <div className="misi" style={{ backgroundColor:'#f5f5f5', padding:'25px'}}>
                <h2>Sasaran</h2>
                <ol>
                <li>Meningkatnya keunggulan Perguruan Tinggi untuk kompetitif yang berbasis Teknologi Informasi dan Komunikasi di masa depan yang nilai-nilai 12 Prinsip Dasar UPI-YPTK Padang</li>
                <li>Meningkatnya kualitas lulusan yang relevan dengan tuntutan masa sekarang dan masa depan</li>
                <li>Meningkatnya sumber daya manusia yang capable dalam menyelenggarakan Tridharma Perguruan Tinggi</li>
                <li>Meningkatnya kerjasama dengan berbagai pihak baik dalam maupun luar negeri dalam penyelenggaraan pendidikan</li>
                <li>Meningkatkan dan menyebarluaskan ilmu pengetahuan dan teknologi</li>
                <li>Terjaganya perkembangan tata kelola yang kapabel dan profesional dalam menyelenggarakan pendidikan</li>
                </ol>
            </div>
            </div>
        </div>

    </div>
  );
};

export default VisiMisi;
