import React from 'react';
import './MainFooter.css'; // Tambahkan file CSS untuk styling
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTiktok } from 'react-icons/fa'; // Import ikon dari react-icons

const MainFooter = () => {
  return (
    <footer className="main-footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* Kolom 1: Tentang */}
            <div className="col-md-5 col-sm-12 ">
                <div className="footer-first-box">
                    <div >
                        <h4 className="footer-title">Tentang UPI YPTK</h4>
                        <p>
                            Universitas Putra Indonesia YPTK Padang adalah salah satu perguruan tinggi swasta terbaik
                            di Indonesia yang fokus pada inovasi dan pendidikan berkualitas.
                        </p>
                    </div>
                    <div>
                        <img src="/footer-asset/logo-upi-dan-yayasan-all-white.png" alt="Upi yptk adang dan yayasan" />
                    </div>
              </div>
            </div>

            {/* Kolom 2: Link Cepat */}
            <div className="col-md-2 col-sm-12 footer-gap">
              <h4 className="footer-title">UNIVERSITAS</h4>
              <ul className="footer-links spacing">
                <li><a href="/sejarah-upi-yptk">Sejarah</a></li>
                <li><a href="/program-studi">Program Studi</a></li>
                <li><a href="/akreditasi-kampus">Akreditas</a></li>
                <li><a href="/kontak">Kontak</a></li>
              </ul>
            </div>

            {/* Kolom 3: Info */}
            <div className="col-md-2 col-sm-12 footer-gap spacing">
              <h4 className="footer-title">Informasi</h4>
              <ul className="footer-links">
                <li><a href="/beasiswa">Beasiswa</a></li>
                <li><a href="/newsroom">Newsroom</a></li>
                <li><a href="/pimpinan-kampus">Pimpinan</a></li>
                <li><a href="/struktur-organisasi-kampus">Struktural</a></li>
              </ul>
            </div>

            {/* Kolom 4: Kontak */}
            <div className="col-md-3 col-sm-12 footer-gap">
              <h4 className="footer-title">Kontak</h4>
              <p>(0751) 776666</p>
              <p style={{ marginTop:'20px'}}>Jl. Raya Lubuk Begalung Padang <br />Sumatera Barat</p>
              <a href="https://maps.app.goo.gl/gRkBB1uZLymcaKyQ9" style={{ marginTop:'20px'}} target='blank'>Google Map</a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className='container'>
            <div className='footer-bottom-box'>
                {/* Copyright */}
                <div>
                    <p style={{textAlign:'center'}}>&copy; 2024 Universitas Putra Indonesia YPTK Padang.</p>
                    <div>
                        <a href="/" target="_blank" rel="noopener noreferrer">Imprint</a>
                        <a href="/" target="_blank" rel="noopener noreferrer">Privacy Statement</a>
                        <a href="/" target="_blank" rel="noopener noreferrer">Accessibility</a>
                    </div>
                </div>

                {/* Ikon Media Sosial */}
                <div>
                <div className="social-icons">
                    <a href="https://www.facebook.com/upi.yptk/" target="_blank" rel="noopener noreferrer">
                    <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com/upiyptk_padang/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                    </a>
                    <a href="https://id.linkedin.com/company/universitas-putra-indonesia-yptk-padang" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn />
                    </a>
                    <a href="https://www.youtube.com/@universitasputraindonesiay5026" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                    </a>
                    <a href="https://www.tiktok.com/@upiyptkpadang" target="_blank" rel="noopener noreferrer">
                    <FaTiktok />
                    </a>
                </div>
                </div>
            </div>          
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
