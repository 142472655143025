import React from "react";
import "./HomePage.css";
import { useNavigate } from 'react-router-dom';

import Header from "../components/Header";
import Highlight from "../post/Highlight";

const HomePage = () => {
    const navigate = useNavigate();  // Hook untuk navigasi

    const handleButtonClick = () => {
        navigate('/newsroom');
    };

  return (
    <div >
        <div>
            <Header />
        </div>

        {/* Section 2 */}
        <div className="homepage-container">
            <div className="home-body-container">
                <div className="content-section">
                    <div className="text-column">
                        <div style={{ gap:'5px', display:'inline-flex', flexDirection:'column'}}>
                            <div style={{ display:'inline-flex'}}>
                                <p style={{ fontSize:'12px', fontWeight:'500', backgroundColor:'#FFD303', padding:'5px 10px'}}>UPI YPTK PADANG</p>
                            </div>
                            <h2 style={{ fontWeight:'600', textTransform:'uppercase', textAlign:'start'}}>Kampus idaman untuk karir impian!</h2>
                            <p style={{ color:'#353535', fontSize:'16px', textAlign:'start'}}>
                                Universitas Putra Indonesia “YPTK” Padang memiliki visi untuk menjadi pusat unggulan dalam menghasilkan lulusan yang berkarakter, bertalenta, dan siap berkontribusi bagi kemajuan bangsa. Melalui berbagai program dan kegiatan, UPI YPTK terus berupaya untuk meningkatkan kualitas pendidikan dan menghasilkan lulusan yang mampu bersaing di tingkat nasional maupun internasional.
                            </p>
                        </div>
                    </div>
                    <div className="image-column">
                    <img src="./homepage-asset/foto-upi-yptk-5.webp" alt="Example" />
                    </div>
                </div>
            </div>
        </div>

        {/* Section 3 - Highlight */}
        <div className="homepage-container">
        <div className="home-body-container newsroom-blok" style={{ flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>
            <hr  />
            <div className="newsroom">
                <h2>Newsroom</h2>
                <button 
                    onClick={handleButtonClick}
                >
                    Lihat Semua
                </button>
            </div>
            </div>
            <div style={{ marginTop: '20px' }}>
            <Highlight />
            </div>
        </div>
        </div>

        {/* Section 4 - Warna dan image */}
            <div className="image-block">
                <img src="./homepage-asset/Element-4.png" alt="Element 4" />
            </div>

        {/* Section 5 - merdeka Belajar Kampus Merdeka */}
        <div className="homepage-container two-column-block">
            {/* Kolom Kiri */}
            <div className="left-column">
                <img src="./homepage-asset//upi-yptk-gallery-1.jpg" alt="Main Visual" className="main-image" />
            </div>
            
            {/* Kolom Kanan */}
            <div className="right-column">
                <img src="./homepage-asset/logo-upi-dan-yayasan-all.png" alt="Logo" className="logo" />
                <h2>Merdeka Belajar, Kampus Merdeka</h2>
                <hr />
                <p>
                    <strong>Universitas Putra Indonesia (UPI) YPTK</strong>  Padang tidak hanya unggul dalam penelitian, tetapi juga berkomitmen untuk memberikan pengalaman belajar yang lebih luas melalui program `Merdeka Belajar Kampus Merdeka`. Mahasiswa diberikan kebebasan untuk mengeksplorasi minat mereka di luar kelas, seperti magang di industri, mengikuti program pertukaran pelajar, atau terlibat dalam proyek kemanusiaan. Dengan demikian, lulusan UPI YPTK Padang tidak hanya memiliki pengetahuan akademik yang kuat, tetapi juga dilengkapi dengan keterampilan praktis dan jaringan yang luas, siap bersaing di dunia kerja yang dinamis.
                </p>
                <button 
                    className="cta-button" 
                    onClick={() => window.open("https://mbkm.upiyptk.ac.id/", "_blank")}
                    >
                    Selengkapnya
                </button>
            </div>
        </div>


        {/* Section 6 - Fakultas */}
        <div
            style={{
                backgroundImage: 'url(/homepage-asset/fakultas-background.png)', // Jalur dari root
                backgroundPosition: 'right top',
                backgroundRepeat: 'no-repeat',
                padding:'0',
                margin:'0',
            }}
        >
            <div className="section-new">
                <h2>Fakultas UPI YPTK</h2>
                <hr />
                <div className="image-grid">
                    <a href="https://filkom.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-ilkom.jpg" alt="Fakultas Ilkom"/>
                    </a>

                    <a href="https://fekon.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-ekonomi-bisnis.jpg" alt="Ekonomi dan Bisnis"/>
                    </a>
                    
                    <a href="https://fpsi.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-psikologi.jpg" alt="Psikologi"/>
                    </a>

                    <a href="https://fkip.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-fkip.jpg" alt="FKIP"/>
                    </a>

                    <a href="http://fdkv.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-dkv.jpg" alt="DKV"/>
                    </a>

                    <a href="https://teknik.upiyptk.ac.id/" target="_blank" rel="noopener noreferrer">
                        <img src="./homepage-asset/Display-fakultas-teknik.jpg" alt="Teknik"/>
                    </a>
                </div>   
            </div>
        </div>


        {/* Section 7 - Riset and Study */}
        <div
            style={{
                backgroundImage: 'url(/homepage-asset/riset-dan-studi.png)', // Jalur dari root
                backgroundPosition: 'left bottom',
                backgroundRepeat: 'no-repeat',
                padding:'0',
                margin:'0',
            }}
        >
            <div className="homepage-container">
                <div className="section-seven">
                    <div className="left-column-7">
                        <h2>Riset dan Study</h2>
                        <p>
                        Kurikulum studi di Universitas Putra Indonesia (UPI) YPTK Padang dipengaruhi oleh fokus penelitian yang kuat dan dirancang untuk membiasakan mahasiswa dengan praktik penelitian mandiri sejak awal. Dengan berbagai pilihan bidang studi, universitas ini menawarkan spektrum luas mata pelajaran yang mewakili universitas komprehensif serta banyak peluang untuk memperoleh kualifikasi profil yang unik dan kompetitif secara nasional maupun internasional.
                        </p>

                        <div>
                            <a href="/program-studi" className="cta-button-7">
                                <span className="button-text">Program Studi</span>
                                <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
                            </a>
                            <hr />
                            <a href="/beasiswa" className="cta-button-7">
                                <span className="button-text">Beasiswa</span>
                                <img src="/right-arrow-long.svg" alt="Arrow" className="arrow-icon" />
                            </a>
                            <hr />
                        </div>

                        <div>
                            <a 
                                href="https://spmb.upiyptk.ac.id/" 
                                className="join-button-8" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                BERGABUNG BERSAMA UPI YPTK
                            </a>
                        </div>
                    </div>

                    <div className="right-column-7">
                        <img src="./homepage-asset/foto upi yptk 6.webp" alt="Riset and Study" />
                    </div>
                </div>
            </div>
        </div>

        {/* Section 8 - Inovasi Teknologi */}
        <div
            style={{
                backgroundImage: 'url(/kampus-back.jpg)',
                backgroundPosition: 'left bottom',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',  // Isi area sepenuhnya
                width: '100%',
                padding: '0',
                margin: '0',
            }}
        >
            <section className="section-eight">
                <div className="content-wrapper">
                    {/* Kolom Kiri */}
                    <div className="left-column-8">
                    <h2>Inovasi Teknologi Berkelanjutan</h2>
                    <p>
                        Sejalan dengan komitmennya dalam menjadi kampus peloper perkembangan teknologi, UPI YPTK Padang secara aktif terlibat dalam pengembangan teknologi terbarukan dalam berbagai bidang.
                    </p>
                    <p>
                        Melalui berbagai inisiatif penelitian, UPI YPTK Padang berupaya menciptakan solusi-solusi inovatif untuk memanfaatkan sumber daya yang ada secara berkelanjutan. Dengan mengintegrasikan berbagai disiplin ilmu, UPI YPTK berkontribusi pada pengembangan teknologi yang dapat mengatasi berbagai tantangan saat ini dan masa depan.
                    </p>
                    </div>
                    {/* Kolom Kanan */}
                    <div className="right-column-8">
                    <h2>Informasi Pendaftaran</h2>
                    <p>
                        Temukan program studi yang tepat dan fasilitas terbaik hanya di UPI YPTK PADANG. Jadilah bagian dari generasi yang menginspirasi!
                    </p>
                    <a 
                        href="https://spmb.upiyptk.ac.id/" 
                        className="join-button-8" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Selengkapnya
                    </a>
                    </div>
                </div>
            </section>

            </div>
        
        
    </div>
  );
};

export default HomePage;
